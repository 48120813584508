export const data = [
  {
    gender: "Female",
    language: "Hindi",
    audio: "https://blackelephantdigital.com/assets/music/CharuSample_H1.mp3",
    text: "Female Hindi Sample 1",
  },
  {
    gender: "Female",
    language: "Hindi",
    audio: "https://blackelephantdigital.com/assets/music/DeepaSample_H.mp3",
    text: "Female Hindi Sample 2",
  },
  {
    gender: "Female",
    language: "English",
    audio: "https://blackelephantdigital.com/assets/music/DeepaSample_E1.mp3",
    text: "Female English Sample 1",
  },
  {
    gender: "Female",
    language: "English",
    audio:
      "https://blackelephantdigital.com/assets/music/Female_Voice_Sample%20_1.mp3",
    text: "Female English Sample 2",
  },
  {
    gender: "Female",
    language: "English",
    audio:
      "https://blackelephantdigital.com/assets/music/Female_Voice_Sample_2.mp3",
    text: "Female English Sample 3",
  },
  {
    gender: "Female",
    language: "Malayalam",
    audio: "https://blackelephantdigital.com/assets/music/Malayalam_Sample.mp3",
    text: "Female Malayalam Sample 1",
  },
  {
    gender: "Female",
    language: "Telugu",
    audio:
      "https://blackelephantdigital.com/assets/music/VO_Sample_(F)_Telugu_1.1.mp3",
    text: "Female Telugu Sample 1",
  },
  {
    gender: "Female",
    language: "Telugu",
    audio:
      "https://blackelephantdigital.com/assets/music/VO_Sample_(F)_Telugu_1.2.mp3",
    text: "Female Telugu Sample 2",
  },
  {
    gender: "Female",
    language: "Telugu",
    audio:
      "https://blackelephantdigital.com/assets/music/VO_Sample_(F)_Telugu_2.mp3",
    text: "Female Telugu Sample 3",
  },
  {
    gender: "Female",
    language: "Telugu",
    audio:
      "https://blackelephantdigital.com/assets/music/VO_Sample_(F)_Telugu_3.mp3",
    text: "Female Telugu Sample 4",
  },
  {
    gender: "Male",
    language: "Hindi",
    audio:
      "https://blackelephantdigital.com/assets/music/GauharRazaSample_H1.mp3",
    text: "Male Hindi Sample 1",
  },
  {
    gender: "Male",
    language: "Hindi",
    audio: "https://blackelephantdigital.com/assets/music/SandeepSample_H1.mp3",
    text: "Male Hindi Sample 2",
  },
  {
    gender: "Male",
    language: "Hindi",
    audio:
      "https://blackelephantdigital.com/assets/music/SunilSuyalSample_H1.mp3",
    text: "Male Hindi Sample 3",
  },
  {
    gender: "Male",
    language: "English",
    audio: "https://blackelephantdigital.com/assets/music/KaranVats_E1.mp3",
    text: "Male English Sample 1",
  },
  {
    gender: "Male",
    language: "English",
    audio: "https://blackelephantdigital.com/assets/music/Anindya_E1.mp3",
    text: "Male English Sample 2",
  },
  {
    gender: "Male",
    language: "English",
    audio:
      "https://blackelephantdigital.com/assets/music/VijayMattoSample_E1.mp3",
    text: "Male English Sample 3",
  },
];

export const languages = ["Hindi", "English", "Malayalam", "Telugu"];

export const genders = ["Female", "Male"];
