import React, { useState, useRef, useEffect } from "react";
import Layout from "../Layout";
import "./style.scss";
import { data, genders, languages } from "./data";

export default function VODirectory() {
  const [currentGender, setCurrentGender] = useState(genders[0]);
  const [currentLanguage, setCurrentLanguage] = useState(languages[0]);
  const [currentTrack, setCurrentTrack] = useState(null);

  function handleGenderChange(gender) {
    setCurrentGender(gender);
    setCurrentLanguage(languages[0]);
  }

  function getGenderlanguages() {
    const languages = data.map((item) => {
      if (currentGender === item.gender) {
        return item.language;
      }
    });
    return [...new Set(languages)].filter(
      (item) => item !== null && item !== undefined
    );
  }

  let currentLanguages = getGenderlanguages();
  //   console.log("current languages" + currentLanguages);

  let audios = data.filter(
    (item) => item.gender === currentGender && item.language === currentLanguage
  );

  function PlayerCard({ track }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const range = useRef(null);
    const updateTimer = useRef(null);
    const audio = useRef(null);

    useEffect(() => {
      clearInterval(updateTimer.current);
      resetValues();

      audio.current = new Audio();
      audio.current.src = track.audio;

      audio.current.load();

      updateTimer.current = setInterval(seekUpdate, 100);

      return () => {
        clearInterval(updateTimer.current);
        audio.current.pause();
      };
    }, []);

    function seekUpdate() {
      let seekPosition = 0;

      // Check if the current track duration is a legible number
      if (!isNaN(audio.current.duration)) {
        seekPosition =
          audio.current.currentTime * (100 / audio.current.duration);

        if (range.current !== null) {
          range.current.value = seekPosition;
        }
      }
    }

    function seekTo() {
      let seekto = audio.current.duration * (range.current.value / 100);

      audio.current.currentTime = seekto;
    }

    function resetValues() {
      if (range.current !== null) {
        range.current.value = 0;
      }
    }

    function playTrack() {
      audio.current.play();
      setIsPlaying(true);
    }

    function pauseTrack() {
      audio.current.pause();
      setIsPlaying(false);
    }

    return (
      <div
        className={`vo__audio__players__card vo__audio__players__card--${track.language.toLowerCase()}`}
      >
        <div className="vo__audio__players__card__headphones">
          <i className="fas fa-headphones-alt"></i>
        </div>
        {!isPlaying ? (
          <button
            onClick={playTrack}
            className="vo__audio__players__card__button"
          >
            <i className="fas fa-play"></i>
          </button>
        ) : (
          <button
            onClick={pauseTrack}
            className="vo__audio__players__card__button"
          >
            <i className="fas fa-pause"></i>
          </button>
        )}

        <input
          ref={range}
          onChange={seekTo}
          className="vo__audio__players__card__range"
          type="range"
        ></input>

        <div className="vo__audio__players__card__text">{track.text}</div>
      </div>
    );
  }

  return (
    <Layout>
      <div className="vo">
        <div className="vo__hero">
          <h1>
            Voice Over
            <br /> Directory
          </h1>
          <p>
            Our-state-of-the-art recording studio offers a comprehensive and
            constantly updated directory of talented and experienced artists.
            This should, quite literally, be music to your ears!
            <br />
            <br />A selection of samples from our favourite voice-over artists
            are available to browse below, with their consent. For specific
            accents, languages, inflections, voice types, purpose-driven
            narrations and budgets, please call us at +91-8130047247 and our
            experienced sound engineers will help narrow down the variety for an
            appropriate choice.
          </p>
        </div>

        <div className="vo__audio">
          <div className="vo__audio__vtabs">
            {genders.map((item, i) => (
              <div
                onClick={() => handleGenderChange(item)}
                className={`vo__audio__vtabs__tab ${
                  item === currentGender && "vo__audio__vtabs__tab--active"
                }`}
                key={`atab_${i}`}
              >
                {item}
              </div>
            ))}
          </div>
          <div
            className={`vo__audio__vtabs2 vo__audio__vtabs2--${currentLanguage.toLowerCase()} `}
          >
            {currentLanguages.map((item, i) => (
              <div
                onClick={() => setCurrentLanguage(item)}
                className={`vo__audio__vtabs__tab ${
                  item === currentLanguage && "vo__audio__vtabs2__tab--active"
                }`}
                key={`atab_${i}`}
              >
                {item}
              </div>
            ))}
          </div>
          <div className="vo__audio__players">
            {audios.map((item, i) => (
              <PlayerCard
                key={`audio-${currentGender + currentLanguage + i}`}
                track={item}
              />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}
